import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './Payments.css';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const today = getTodayDate();
    setFromDate(today);
    setToDate(today);
    fetchData(today, today);
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchData = async (from = fromDate, to = toDate) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('http://localhost:8801/api/report/range', {
        params: { type, fromDate: from, toDate: to },
      });

      if (response.status === 200) {
        const data = response.data.data;
        setPayments(data);
        setTotalCount(data.length);
      } else {
        handleNoData();
      }
    } catch {
      handleNoData('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleNoData = (msg = 'No data available for the selected date range.') => {
    setPayments([]);
    setTotalCount(0);
    setError(msg);
  };

  const handleExportToExcel = () => {
    if (payments.length === 0) {
      alert('No data available to export.');
      return;
    }

    const wb = XLSX.utils.book_new();
    const data = payments.map(payment => ({
      'Student ID': payment.Excel_paysstid,
      'Student Name': payment.excel_stname || 'N/A',
      'Date:Time': payment.Excel_payDateTime,
      'Amount': payment.Excel_payAmount,
      'Place': payment.Excel_paystore,
      'Status': payment.Excel_paytype,
      'Type': payment.Excel_paytype,
      'Tr No.': payment.Excel_payRemarks
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = Array(8).fill({ wpx: 150 });

    const cellStyle = {
      border: { top: { style: 'thick', color: { rgb: '000000' } }, bottom: { style: 'thick', color: { rgb: '000000' } }, left: { style: 'thick', color: { rgb: '000000' } }, right: { style: 'thick', color: { rgb: '000000' } } },
      alignment: { horizontal: 'center', vertical: 'center' }
    };

    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellAddress]) continue;
        ws[cellAddress].s = cellStyle;
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Payments');
    XLSX.writeFile(wb, `Payments_Report_${getTodayDate()}.xlsx`);
  };

  return (
    <div className="payments-container">
      <h1>Sales Report</h1>

      <div className="filter-section">
        <div className="filter-item">
          <label>Type:</label>
          <div className="radio-group">
            <label>
              <input type="radio" value="" checked={type === ''} onChange={e => setType(e.target.value)} />
              All
            </label>
            <label>
              <input type="radio" value="Credit" checked={type === 'Credit'} onChange={e => setType(e.target.value)} />
              Credit
            </label>
            <label>
              <input type="radio" value="Debit" checked={type === 'Debit'} onChange={e => setType(e.target.value)} />
              Debit
            </label>
          </div>
        </div>

        <div className="filter-item date-item">
          <label>From:</label>
          <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} />
          <label>To:</label>
          <input type="date" value={toDate} onChange={e => setToDate(e.target.value)} />
        </div>

        <div className="action-buttons">
          <button onClick={() => fetchData()}>Submit</button>
          <button onClick={handleExportToExcel}>Excel</button>
          <button onClick={() => window.location.reload()}>Close</button>
        </div>
      </div>

      {loading && <div className="loading">Loading...</div>}
      {error && <p className="error">{error}</p>}

      <p>Total Records: {totalCount}</p>

      <table>
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Date:Time</th>
            <th>Amount</th>
            <th>Place</th>
            <th>Status</th>
            <th>Type</th>
            <th>Tr No.</th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map(payment => (
              <tr key={payment.Excel_paysstid}>
                <td>{payment.Excel_paysstid}</td>
                <td>{payment.excel_stname || 'N/A'}</td>
                <td>{payment.Excel_payDateTime}</td>
                <td>{payment.Excel_payAmount}</td>
                <td>{payment.Excel_paystore}</td>
                <td>{payment.Excel_payRemarks}</td>
                <td>{payment.Excel_paytype}</td>
                <td>{payment.Excel_payItems}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="no-data">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Payments;
